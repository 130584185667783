import React, { useCallback, useEffect, useRef, useState } from "react";

interface IParticle {
  r: number;
  alpha: number;
  hue: number;
  pos: { x: number; y: number; z: number };
  oldPos: { x: number; y: number }[];
  vel: { x: number; y: number };
}

interface IProps {
  blurWidth?: number;
  width?: number;
  height?: number;
}

const Embers: React.FC<IProps> = ({ blurWidth, width, height }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationRef = useRef<number | null>(null);
  const [particles, setParticles] = useState<IParticle[]>([]);
  const [canvasWidth, setCanvasWidth] = useState<number>(
    width || window.innerWidth
  );
  const [canvasHeight, setCanvasHeight] = useState<number>(
    height || window.innerHeight
  );

  const addParticle = useCallback(() => {
    const z = Math.random();
    const r = (1 - z) * Math.random() * 18 + 2;
    const alpha = z * 64 + 192;
    const hue = (Math.random() * 50 + 360) % 360;
    const pos = {
      x: Math.random() * canvasWidth * 0.8 + canvasWidth * 0.1,
      y: canvasHeight + r,
      z: z * 20 - 10,
    };
    const oldPos: { x: number; y: number }[] = [];
    const vel = {
      x: (Math.random() * 2 - 1) * 0.004 * canvasWidth,
      y: Math.random() * -0.01 * canvasHeight,
    };
    setParticles((oldParticles) => [
      ...oldParticles,
      { r, alpha, hue, pos, oldPos, vel },
    ]);
  }, [canvasHeight, canvasWidth]);

  useEffect(() => {
    const handleResize = () => {
      if (!height) {
        setCanvasHeight(window.innerHeight);
      }

      if (!width) {
        setCanvasWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", handleResize);
  });

  const update = useCallback(() => {
    if (Math.random() < 0.4) {
      addParticle();
    }

    setParticles((oldParticles) =>
      oldParticles
        .map((p) => {
          p.oldPos = [...p.oldPos, { x: p.pos.x, y: p.pos.y }].slice(-2);
          p.pos.x += p.vel.x;
          p.pos.y += p.vel.y;
          p.alpha -= 2;
          p.r *= 0.995;
          p.vel.x *= 0.985;
          p.vel.y *= 1.01;
          return p;
        })
        .filter((p) => p.alpha > 0)
    );

    animationRef.current = requestAnimationFrame(update);
  }, [addParticle]);

  useEffect(() => {
    animationRef.current = requestAnimationFrame(update);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [update]);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.fillStyle = "rgba(0, 0, 0, 0)";
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        for (const p of particles) {
          ctx.strokeStyle = `hsla(${p.hue}, 85%, ${
            (p.alpha / 255) * 25 + 40
          }%, ${p.alpha / 255})`;
          ctx.lineWidth = p.r;
          ctx.beginPath();
          p.oldPos.forEach((n, i) => {
            ctx[i ? "lineTo" : "moveTo"](n.x, n.y);
          });
          ctx.stroke();
        }
      }
    }
  }, [blurWidth, particles]);

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
      style={{ filter: `blur(${blurWidth}px)` }}
    />
  );
};

export default Embers;
