import logo from "Images/CompanyLogoDetailed.svg";
import Embers from "UI/Components/Embers";
import "./App.css";

const App = () => {
  return (
    <div className='app'>
      <div id='app-content-container'>
        <img src={logo} alt='' id='main-logo' />
        <div id='text-container'>
          <h2 id='text-header'>LumenDocs Alpha Coming Soon!</h2>
          <p id='text-content'>
            LumenDocs utilizes Machine Learning to enhance Documentation,
            enabling Software Engineering teams to reduce or remove "Tribal
            Knowledge".
            <br />
            <br />
            We are focused on creating a product that allows for collaboration
            and onboarding to be as seamless as possible in any size company.
          </p>
        </div>
        <div id='footer-container'>
          <p id='footer-contact'>
            Contact us at{" "}
            <a
              href='mailto: LumenDocsContact@gmail.com'
              id='footer-contact-email'
            >
              LumenDocsContact@gmail.com
            </a>
          </p>
        </div>
      </div>
      <div id='app-background' />
      <Embers blurWidth={3} />
    </div>
  );
};

export default App;
